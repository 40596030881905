import {isDefined, set} from "@vueuse/core";
import {useProgressIndicatorStore} from "~/stores/useProgressIndicatorStore";
import {
  EXO_CLICK_ID,
  HILLTOP,
  KADAM_ID,
  OCTO_CLICK,
  PUB_ADS_ID,
  TRAFFIC_STARTS_ID,
  UNDER_SITE
} from "~/const/ADVERTISER";
import {toValue} from "vue";
import {format,  startOfYesterday,} from "date-fns";

export const useAnalyticsStore = defineStore('analyticsStore', () => {
  const authStore = useAuthStore();
  const report: Ref<[] | null> = ref([]);
  const initialReport: Ref<[] | null> = ref([]);
  const shortInfo = ref({});
  const dayRevenue = ref({"pubads": {network_slug: 'pubserv'}});
  const yesterdayRevenue = ref({"pubads": {network_slug: 'pubserv'}});
  const { username,token } = authStore;
  const trafficNetworksStore = useTrafficNetworksStore();

  const getFirstReport = computed(() => {
    const { Date, ...rest } = toValue(report)[0] ?? {};
    return rest;
  });

  const totalDayRevenue = computed(() =>
    Object.values(toValue(dayRevenue)).reduce((acc, item) => {
      acc.impressions += parseFloat(item.impressions) || 0;
      acc.profit = parseFloat((acc.profit + parseFloat(item.profit || 0)).toFixed(2));
      acc.ecpm += parseFloat(item.ecpm) || 0;
      return acc;
    }, { impressions: 0, profit: 0, ecpm: 0 })
  );



  interface GenerateReportParams {
    range?: RangeKey;
    groupRange?: GroupRangeKey,
    groupBy?: [GroupBy] | GroupBy[''],
    country?: object,
    date_from?: string | null,
    date_to?: string | null,
    placement: string,
    adviser: string,
  }

  function setReport(arr) {
    set(report, arr);
  }


  const generateReport = async ({
     date_from = null,
     country = {},
     date_to = null,
     zone = '',
     adviser = '',
                                  groupBy = '',
   }: GenerateReportParams) => {
    const progressStore = useProgressIndicatorStore();

    progressStore.updateStatus(true);
    let reportRef = null;
    switch (parseInt(adviser)) {
      case PUB_ADS_ID:
        reportRef = await getReportPubAds({
          date_from,
          country: country.code,
          zone,
          date_to,
        })
        break;
      case EXO_CLICK_ID:
        reportRef =  await getReportExoclick({
          date_from,
          date_to,
          country: country.iso3,
          zone,
        });
        break;
      case KADAM_ID:
        reportRef =  await getReportKadam({
          date_from,
          date_to,
          country: country.iso3,
          zone,
        });
        break;
      case OCTO_CLICK:
        reportRef = await getReportOctoClick({
          date_from,
          date_to,
          country: country.value,
          zone,
        });
        break;
      case HILLTOP:
        reportRef = await getReportHilltop({
          date_from,
          date_to,
          country: country.code,
          zone,
        });
        break;
      case TRAFFIC_STARTS_ID:
        reportRef = await getReportTrafficStarts({
          date_from,
          date_to,
          country: country.code,
          zone,
        });
      case UNDER_SITE:
        reportRef = await getReportUnderSite({
          date_from,
          date_to,
          country: country.code,
          zone,
        });
        break;


      default:
        const data =  await getAllReports({
          date_from,
          date_to,
          countryCode: country.code,
          countryISO: country.iso3,
          countryValue: country.value,
        });

        reportRef =  groupBy.length ? data[1].value.value: data[0].value.value;
        break;
    }

    set(report, reportRef);
    progressStore.updateStatus(false);
  };

  async function getReportPubAds({
                                   date_from = null,
                                   date_to = null,
                                   country = '',
                                   zone = ''
                                 }) {
    return await $fetch('/api/adviser-api/pubads/get-stat', {
      query: {
        token: toValue(token),
        username: toValue(username),
        country,
        date_from,
        date_to,
        zone,
        siteId: toValue(authStore.userInfo.siteId),
    }});
  }

  async function getReportHilltop( {
                                    date_from = '',
                                     country = '',
                                    date_to = '',
                                     placement = ''
                                   } ) {
    if (!toValue(trafficNetworksStore.getTrafficNetworksById(3))?.key.length) { return []}
    return $fetch('/api/adviser-api/hilltop/get-stat', {
      query: {
        date_from,
        date_to,
        country,
        token: toValue(trafficNetworksStore.getTrafficNetworksById(3))?.key,
      }
    })
  }

  async function getReportTrafficStarts({
                                     date_from = null,
                                     country = '',
                                     date_to = null,
                                     placement = ''
                                   }) {
    if (!toValue(trafficNetworksStore.getTrafficNetworksById(5))?.key.length) { return []}
    return $fetch('/api/adviser-api/traffic-stars/get-stat', {
      query: {
        token: toValue(trafficNetworksStore.getTrafficNetworksById(5))?.key,
        date_from,
        country,
        date_to
      }
    })
  }

  async function getReportUnderSite({
                                          date_from = null,
                                          country = '',
                                          date_to = null,
                                          zone = ''
                                        }) {
    if (!toValue(trafficNetworksStore.getTrafficNetworksById(7))?.key.length) { return [] }
    return $fetch('/api/adviser-api/undersite/get-stat', {
      query: {
        token: toValue(trafficNetworksStore.getTrafficNetworksById(7))?.key,
        date_from,
        country,
        date_to,
        params: JSON.stringify(trafficNetworksStore.getTrafficNetworksById(7)?.params ?? []),
        zone
      }
    })
  }

  async function getReportExoclick({
                                     date_from = '',
                                     country = '',
                                     date_to = '',
                                     placement = ''
                                   }) {
    if (!toValue(trafficNetworksStore.getTrafficNetworksById(2))?.key.length) { return []}
    return $fetch('/api/adviser-api/exoclick/get-stat', {
      query: {
        country,
        date_from,
        date_to,
        token: toValue(trafficNetworksStore.getTrafficNetworksById(2))?.key,
      }
    })
  }

  async function getReportOctoClick( {
                                    date_from = '',
                                    country = null,
                                    date_to = '',
                                    placement = ''
                                  } ) {
    if (!toValue(trafficNetworksStore.getTrafficNetworksById(4))?.key.length) { return []}
    return $fetch('/api/adviser-api/octoclick/get-stat', {
      query: {
        date_from,
        date_to,
        ...(isDefined(country) && {country}),
        token: toValue(trafficNetworksStore.getTrafficNetworksById(4))?.key,
      }
    })
  }

  async function getReportKadam( {
                                       date_from = '',
                                       country = null,
                                       date_to = '',
                                       placement = ''
                                     } ) {
    if (!toValue(trafficNetworksStore.getTrafficNetworksById(6))?.key.length) { return []}
    return $fetch('/api/adviser-api/kadam/get-stat', {
      query: {
        date_from,
        date_to,
        ...(isDefined(country) && {country}),
        token: toValue(trafficNetworksStore.getTrafficNetworksById(6))?.key,
      }
    })
  }

  async function getAllReports({
                                 date_from = '',
                                 date_to = '',
                                 countryCode= null,
                                 countryISO = null,
                                 countryValue =  null,
                               }) {
  return $fetch('/api/adviser-api/all/get-stat', {
      query: {
        date_from,
        date_to,
        username: toValue(username),
        tokenPubAds: toValue(token),
        tokenExo: toValue(trafficNetworksStore.getTrafficNetworksById(2))?.key,
        tokenHilltop: toValue(trafficNetworksStore.getTrafficNetworksById(3))?.key,
        tokenUnderSite: toValue(trafficNetworksStore.getTrafficNetworksById(7))?.key,
        tokenOcto: toValue(trafficNetworksStore.getTrafficNetworksById(4))?.key,
        tokenTraffic: toValue(trafficNetworksStore.getTrafficNetworksById(5))?.key,
        tokenKadam: toValue(trafficNetworksStore.getTrafficNetworksById(6))?.key || '',
        params: JSON.stringify(trafficNetworksStore.getTrafficNetworksById(7)?.params ?? []),
        siteId: toValue(authStore.userInfo.siteId),
        ...(isDefined(countryCode) && {countryCode}),
        ...(isDefined(countryISO) && {countryISO}),
        ...(isDefined(countryValue) && {countryValue})
      }
    })
  }

  function setShortInfo(info) {
    set(shortInfo, info);
  }

  async function fetchAdviserStats(networkSlug, token, date_from = new Date(new Date().toISOString()).toLocaleDateString('en-CA'), date_to = new Date(new Date().toISOString()).toLocaleDateString('en-CA')) {
    const loading = ref(true); // Reactive loading status
    let result = null;

    try {
      // Make the API request
      const data = await $fetch(`/api/adviser-api/${networkSlug}/get-stat`, {
        params: {
          token,
          username,
          siteId: authStore.userInfo.siteId,
          date_from,
          date_to,
          params: JSON.stringify(trafficNetworksStore.getTrafficNetworksBySlug(networkSlug)?.params ?? []),
        },
      });

      // Process response data
      result = data[0]; // Assuming the first item in the response array is required
    } catch (error) {
      console.error('Fetch error:', error);
    } finally {
      loading.value = false; // Loading finished
    }

    // Return the result and loading status
    return { result, loading };
  }
  async function fetchDayStat(day, date) {
    const data = await Promise.all(
      trafficNetworksStore.trafficNetworks.map(async network => {
        const response = !network.needKey || network.key.length > 0 ? await fetchAdviserStats(
          network.network_slug,
          network.needKey ? network.key : token,
          date,
          date,
        ) : {result :{profit: 0, impressions: 0}};
        return { network_slug: network.network_slug,
          adviser_network_id: network.adviser_network_id,
          result: response.result,
          name: network.name,
          needKey: network.needKey,
          url: network.url,
          key: network.key,
          id: network.id };
      })
    );
    data.forEach(item => {
      day.value[item.network_slug] = {
        ...item.result,
        name: item.name,
        network_slug: item.network_slug,
        needKey: item.needKey, key: item.key,
        adviser_network_id: item.adviser_network_id,
        loadingState: false,
        id: item.id,
        url: item.url,
      }; // Додає або перезаписує результат
    });
  }

  async function fetchTodayStats() {
    await fetchDayStat(dayRevenue, new Date(new Date().toISOString()).toLocaleDateString('en-CA'))
  }



  async function fetchYesterdayRevenueDayStats() {
    const yesterdayDate = format(startOfYesterday(), 'yyyy-MM-dd', { timeZone: 'UTC' });
    await fetchDayStat(yesterdayRevenue, yesterdayDate);
  }



  return {
    report,
    initialReport,
    getFirstReport,
    generateReport,
    dayRevenue,
    setReport,
    shortInfo,
    totalDayRevenue,
    yesterdayRevenue,
    fetchTodayStats,
    fetchAdviserStats,
    fetchYesterdayRevenueDayStats,
  }
})
